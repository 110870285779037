import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import Button from '../../button'
import BHLink from '../../link'

const shouldForwardProp = (prop) => !['open', 'anchor'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
`

const NavLinkStyled = styled(BHLink)`
	padding: 16px 24px;
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`

const NavItem = styled(Typography)`
	&.menu-item-active {
		${NavLinkStyled} {
			text-decoration: underline;
		}
	}
`

const NavSubMenu = styled('div', { shouldForwardProp })`
	position: fixed;
	top: 0;
	width: 100%;
	background: ${({ theme }) => theme.palette.white.main};
	height: 100%;
	padding: 18px;
	will-change: transition;
	z-index: 2;
	${({ theme }) => theme.scrollbar};
	${({ anchor }) =>
		anchor === 'left'
			? `
					left: -100%;
					transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `
			: `
					right: -100%;
					transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `}
	&.nav-open {
		${({ anchor }) => (anchor === 'left' ? 'left: 0%;' : 'right: 0%;')}
		&.nav-moved {
			${({ anchor }) => (anchor === 'left' ? 'left: 100%;' : 'right: 100%;')}
		}
	}
`

const ParentNavTitle = styled(BHLink)`
	margin: 40px auto 16px 22px;
	display: block;
	min-height: 20px;
`

const SubNavLinkStyled = styled(BHLink)`
	padding: 14px 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	.info {
		background-color: var(--color-pink);
		color: ${({ theme }) => theme.palette.common.white};
		display: inline-block;
		font-family: ${({ theme }) => theme.typography.fontFamily};
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		font-stretch: normal;
		font-weight: ${({ theme }) => theme.typography.fontWeight};
		letter-spacing: 0;
		margin-left: ${({ theme }) => theme.spacing(2.5)};
		padding: ${({ theme }) => theme.spacing(0.5)};
	}
`

const SubContainerNavItem = styled('div')``

const SubNavItem = styled(Typography)`
	&.submenu-item-active {
		${SubNavLinkStyled} {
			text-decoration: underline;
		}
	}
`

const BackButton = styled(Button)`
	margin: 17px auto 0 26px;
	border: 0;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-weight: normal;
	.MuiButton-startIcon > *:nth-of-type(1) {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

const SubNavCmsBlock = styled('div')`
	padding: 16px 24px;

	img {
		display: block;
		width: 100%;
	}
`

export {
	BackButton,
	NavMenu,
	NavItem,
	NavLinkStyled,
	NavSubMenu,
	SubNavItem,
	SubNavLinkStyled,
	SubContainerNavItem,
	SubNavCmsBlock,
	ParentNavTitle,
}
